import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import AuthUser from './AuthUser';
import Login from './admin/Login/Login';
import AdminDashboard from './admin/AdminDash';
import AdminProfile from './admin/AdminProfile';
import OperationalDashBoard from './admin/OperationalDashBoard';
import CustomerDashBoard from './admin/CustomerDashBoard';
import FinanceDashBoard from './admin/FinanceDashBoard';
import SystemSettingDashBoard from './admin/SystemSettingDashBoard';
import CmsDashBoard from './admin/CmsDashBoard';
import ShipmentList from './admin/Shipment/Shipmentlist'; 
import ShipmentDetails from './admin/Shipment/ShipmentDetails';
import ShipmentTracking from './admin/Shipment/ShipmentTracking';
import ShipmentLabel from './admin/Shipment/ShipmentLabel';
import ShipmentInvoice from './admin/Shipment/ShipmentInvoice';
import ShipmentPrintReceipt from './admin/Shipment/ShipmentPrintReceipt';
import Createshipment from './admin/Shipment/CreateShipment';
import Bulkshipmentimport from './admin/Shipment/Bulkshipmentimport';
import RunningPickupList from './admin/PickupManagement/RunningPickupList';
import Generatepickup from './admin/PickupManagement/Generatepickup';
import Generatepickupschedulecustomer from './admin/PickupManagement/Generatepickupschedulecustomer';
import Generatepickupscheduleagent from './admin/PickupManagement/Generatepickupscheduleagent';
import PrsPrint from './admin/PickupManagement/PrsPrint';
import PickupDetails from './admin/PickupManagement/PickupDetails';
import PickupStatusUpdate from './admin/PickupManagement/PickupStatusUpdate';
import ScheduledPickupList from './admin/PickupManagement/ScheduledPickupList'; 
import CustomerScheduledPickupList from './admin/PickupManagement/CustomerScheduledPickupList';
import BagList from './admin/BagManagement/BagList';
import BagPrint from './admin/BagManagement/BagPrint';
import BagDetails from './admin/BagManagement/BagDetails';
import AddBag from './admin/BagManagement/AddBag';
import BagLabel from './admin/BagManagement/BagLabel';
import BagManifestList from './admin/BagManifestManagment/BagManifestList';
import AddBagManifest from './admin/BagManifestManagment/AddBagManifest';
import BagManifestDetails from './admin/BagManifestManagment/BagManifestDetails';
import ShipmentManifestList from './admin/ShipmentManifestManagment/ShipmentManifestList';
import AddShipmentManifest from './admin/ShipmentManifestManagment/AddShipmentManifest';
import ShipmentManifestDetails from './admin/ShipmentManifestManagment/ShipmentManifestDetails';
import Manidest_print from './admin/ShipmentManifestManagment/Manifest_print';
import DrsList from './admin/DRSManagement/DrsList';
import AddDrs from './admin/DRSManagement/AddDrs';
import DrsStatusUpdate from './admin/DRSManagement/DrsStatusUpdate';
import DrsDetails from './admin/DRSManagement/DrsDetails';
import DrsPrint from './admin/DRSManagement/DrsPrint';
import WarehouseScannerDetails from './admin/GlobalReceiveManagment/WarehouseScannerDetails';
import WarehouseScannerPickup from './admin/GlobalReceiveManagment/WarehouseScannerPickup';
import EmailTemplateDetail from './admin/GeneralSettings/EmailTemplateDetail';
import ServiceList from './admin/ServiceManagement/ServiceList';
import ServicePricing from './admin/ServiceManagement/ServicePricing';
import ServiceAdd from './admin/ServiceManagement/AddService';
import ServiceOtherPricing from './admin/ServiceManagement/ServiceOtherPricing';
import ZoneAdd from './admin/ZoneManagement/ZoneAdd';
import ZoneList from './admin/ZoneManagement/ZoneList';
import ZoneEdit from './admin/ZoneManagement/ZoneEdit';
import ZoneSetInternational from './admin/ZoneManagement/ZoneSetInternational';
import ZoneSetDomestic from './admin/ZoneManagement/ZoneSetDomestic';
import ZoneSetList from './admin/ZoneManagement/ZoneSetList';
import CustomerList from './admin/CustomerManagement/CustomersList';
import DriverList from './admin/DriverManagement/DriverList';
import HubList from './admin/HubManagement/HubList';
import StaffList from './admin/StaffManagement/StaffList';
import AgentList from './admin/AgentManagement/AgentList';
import CustomerDetail from './admin/CustomerManagement/CustomerDetail';
import Routelist from './admin/RouteManagement/Routelist';
import RouteAdd from './admin/RouteManagement/RouteAdd';
import RouteEdit from './admin/RouteManagement/RouteEdit';
import Countrylist from './admin/LocationManagement/Countrylist';
import Statelist from './admin/LocationManagement/Statelist';
import Citylist from './admin/LocationManagement/Citylist';
import CountryAdd from './admin/LocationManagement/CountryAdd';
import CountryEdit from './admin/LocationManagement/CountryEdit';
import StateAdd from './admin/LocationManagement/StateAdd';
import StateEdit from './admin/LocationManagement/StateEdit';
import CityAdd from './admin/LocationManagement/CityAdd';
import CityEdit from './admin/LocationManagement/CityEdit';
import Pincodelist from './admin/LocationManagement/Pincodelist';
import ImportPincode from './admin/LocationManagement/ImportPincode';
import PincodeAdd from './admin/LocationManagement/PincodeAdd';
import PincodeEdit from './admin/LocationManagement/PincodeEdit';
import ShipmentReport from './admin/FinanceManagement/ShipmentReport';
import PaymentReport from './admin/FinanceManagement/PaymentReport';
import ClientSalesReport from './admin/FinanceManagement/ClientSalesReport';
import HubReport from './admin/FinanceManagement/HubReport';
import AgentReport from './admin/FinanceManagement/AgentReport';
import DriverReport from './admin/FinanceManagement/DriverReport';
import CODReport from './admin/FinanceManagement/CODReport';
import Stocklist from './admin/StockManagement/Stocklist';
import CustomerAdd from './admin/CustomerManagement/CustomerAdd';
import CustomerEdit from './admin/CustomerManagement/CustomerEdit';
import CustomerServices from './admin/CustomerManagement/CustomerServices';
import CustomerPricing from './admin/CustomerManagement/CustomerPricing';
import CustomerShipments from './admin/CustomerManagement/CustomerShipments';
import CustomerChangePassword from './admin/CustomerManagement/ChangePassword';
import AgentAdd from './admin/AgentManagement/AgentAdd';
import AgentDetail from './admin/AgentManagement/AgentDetail';
import AgentEdit from './admin/AgentManagement/AgentEdit';
import AgentChangePassword from './admin/AgentManagement/AgentChangePassword';
import AgentShipments from './admin/AgentManagement/AgentShipments';
import AgentServices from './admin/AgentManagement/AgentServices';
import AgentPricing from './admin/AgentManagement/AgentPricing';
import HubAdd from './admin/HubManagement/AddHub';
import HubEdit from './admin/HubManagement/EditHub';
import HubPayout from './admin/HubManagement/HubPayout';
import ShipmentUpdateStatus from './admin/Shipment/ShipmentUpdateStatus';
import ShipmentEdit from './admin/Shipment/ShipmentEdit';
import ConsignmentNote from './admin/Shipment/ConsignmentNote';
import Orderinvoice from './admin/Shipment/Oderinvoice';
import StaffAdd from './admin/StaffManagement/AddStaff';
import StaffEdit from './admin/StaffManagement/StaffEdit';
import Privillages from './admin/StaffManagement/Privillages';
import DriverAdd from './admin/DriverManagement/AddDriver';
import DriverEdit from './admin/DriverManagement/EditDriver';
import AllSettings from './admin/GeneralSettings/AllSettings';
import Weightdiscrepancy from './admin/WeightDiscrepancy/Weightdiscrepancy';
import ForwardShipmentManifestList from './admin/ForwardThroughManifestManagment/ShipmentManifestList';
import AddForwardShipmentManifest from './admin/ForwardThroughManifestManagment/AddForwardShipmentManifest';
import ForwardManidest_print from './admin/ForwardThroughManifestManagment/ForwardManidest_print';
import BulkForwardShipment from './admin/Shipment/BulkForwardShipment';


function App() {
  const {getToken}=AuthUser();
  if(!getToken()){
    return(
      <CookiesProvider>
        <Router>
              <Routes>
                <Route exact path='/' element={<Login/>} />
              </Routes>
            </Router>
      </CookiesProvider>
    )
  }else{
    return (
      <CookiesProvider>
        <Router>
              <Routes>
                <Route exact path='/' element={<Login/>} />
                <Route exact path='/Admin-Dash' element={<AdminDashboard />} />
                <Route exact path='/OperationalDashBoard' element={<OperationalDashBoard />} />
                <Route exact path='/CustomerDashBoard' element={<CustomerDashBoard />} />
                <Route exact path='/FinanceDashBoard' element={<FinanceDashBoard />} />
                <Route exact path='/CmsDashBoard' element={<CmsDashBoard />} />
                <Route exact path='/SystemSettingDashBoard' element={<SystemSettingDashBoard />} />
                <Route exact path='/AdminProfile/:UniqueId' element={<AdminProfile />} />
                <Route exact path='/Shipmentlist/:type' element={<ShipmentList />} />
                <Route exact path='/ShipmentDetails/:slip_no' element={<ShipmentDetails />} />
                <Route exact path='/ShipmentTracking/:slip_no' element={<ShipmentTracking />} />
                <Route exact path='/ShipmentLabel' element={<ShipmentLabel />} />
                <Route exact path='/ShipmentInvoice/:slip_no' element={<ShipmentInvoice />} />
                <Route exact path='/ShipmentPrintReceipt/:slip_no' element={<ShipmentPrintReceipt />} />
                <Route exact path='/Createshipment' element={<Createshipment />} />
                <Route exact path='/Bulkshipmentimport' element={<Bulkshipmentimport />} />
                <Route exact path='/PickupList/:type' element={<RunningPickupList />} />
                <Route exact path='/Generatepickup' element={<Generatepickup />} />
                <Route exact path='/Generatepickupschedulecustomer' element={<Generatepickupschedulecustomer />} />
                <Route exact path='/Generatepickupscheduleagent' element={<Generatepickupscheduleagent />} />
                <Route exact path='/PrsPrint/:slipno' element={<PrsPrint />} />
                <Route exact path='/PickupDetails/:drsid' element={<PickupDetails />} />
                <Route exact path='/PickupStatusUpdate/:drsid' element={<PickupStatusUpdate />} />
                <Route exact path='/ScheduledPickupList/:type' element={<ScheduledPickupList />} />
                <Route exact path='/CustomerScheduledPickupList/:sp_no/:cust_id' element={<CustomerScheduledPickupList />} />
                <Route exact path='/BagList/:type' element={<BagList />} />
                <Route exact path='/BagPrint' element={<BagPrint />} />
                <Route exact path='/BagDetails/:drsid' element={<BagDetails />} />
                <Route exact path='/AddBag' element={<AddBag />} />
                <Route exact path='/BagLabel/:uniqueid' element={<BagLabel />} />
                <Route exact path='/BagManifestList/:type' element={<BagManifestList />} />
                <Route exact path='/AddBagManifest' element={<AddBagManifest />} />
                <Route exact path='/BagManifestDetails/:uniqueid' element={<BagManifestDetails />} />
                <Route exact path='/ShipmentManifestList/:type' element={<ShipmentManifestList />} />
                <Route exact path='/AddShipmentManifest' element={<AddShipmentManifest />} />
                <Route exact path='/ShipmentManifestDetails/:uniqueid' element={<ShipmentManifestDetails />} />
                <Route exact path='/Manidest_print/:slipno' element={<Manidest_print />} />
                <Route exact path='/ShipmentUpdateStatus/:slipno' element={<ShipmentUpdateStatus />} />
                <Route exact path='/ShipmentEdit/:slipno' element={<ShipmentEdit />} />
                <Route exact path='/ConsignmentNote/:slipno' element={<ConsignmentNote />} />
                <Route exact path='/Orderinvoice/:slipno' element={<Orderinvoice />} />
                <Route exact path='/DrsList/:type' element={<DrsList />} />
                <Route exact path='/AddDrs' element={<AddDrs />} />
                <Route exact path='/DrsStatusUpdate/:drsid' element={<DrsStatusUpdate />} />
                <Route exact path='/DrsDetails/:drsid' element={<DrsDetails />} />
                <Route exact path='/DrsPrint/:slipno' element={<DrsPrint />} />
                <Route exact path='/WarehouseScannerDetails' element={<WarehouseScannerDetails />} />
                <Route exact path='/WarehouseScanner/:type' element={<WarehouseScannerPickup />} />
                <Route exact path='/Settings' element={<AllSettings/>} />
                <Route exact path='/EmailTemplateDetail/:TempId' element={<EmailTemplateDetail />} />
                <Route exact path='/ServiceList/:type' element={<ServiceList />} />
                <Route exact path='/ServicePricing/:sid/:type' element={<ServicePricing />} />
                <Route exact path='/ServiceOtherPricing/:sid' element={<ServiceOtherPricing />} />
                <Route exact path='/ServiceAdd' element={<ServiceAdd />} />
                <Route exact path='/ZoneAdd' element={<ZoneAdd />} />
                <Route exact path='/ZoneList' element={<ZoneList />} />
                <Route exact path='/ZoneEdit/:unique_id' element={<ZoneEdit />} />
                <Route exact path='/ZoneSetInternational' element={<ZoneSetInternational />} />
                <Route exact path='/ZoneSetDomestic' element={<ZoneSetDomestic />} />
                <Route exact path='/ZoneSetList/:type' element={<ZoneSetList />} />
                <Route exact path='/CustomerList/:type' element={<CustomerList />} />
                <Route exact path='/DriverList' element={<DriverList />} />
                <Route exact path='/DriverAdd' element={<DriverAdd />} />
                <Route exact path='/DriverEdit/:Id' element={<DriverEdit />} />
                <Route exact path='/HubAdd' element={<HubAdd />} />
                <Route exact path='/HubList' element={<HubList />} />
                <Route exact path='/EditHub/:UniqueId' element={<HubEdit />} />
                <Route exact path='/HubPayout/:UniqueId' element={<HubPayout />} />
                <Route exact path='/StaffList' element={<StaffList />} />
                <Route exact path='/StaffAdd' element={<StaffAdd />} />
                <Route exact path='/EditStaff/:UniqueId' element={<StaffEdit />} />
                <Route exact path='/Privillages' element={<Privillages />} />
                <Route exact path='/AgentList/:type' element={<AgentList />} />
                <Route exact path='/CustomerDetail/:UniqueId' element={<CustomerDetail />} />
                <Route exact path='/Routelist' element={<Routelist />} />
                <Route exact path='/RouteAdd' element={<RouteAdd />} />
                <Route exact path='/RouteEdit/:id' element={<RouteEdit />} />
                <Route exact path='/Countrylist' element={<Countrylist />} />
                <Route exact path='/Statelist/:country' element={<Statelist />} />
                <Route exact path='/Citylist/:country/:state' element={<Citylist />} />
                <Route exact path='/CountryAdd' element={<CountryAdd />} />
                <Route exact path='/CountryEdit/:id' element={<CountryEdit />} />
                <Route exact path='/StateAdd/:country' element={<StateAdd />} />
                <Route exact path='/StateEdit/:id' element={<StateEdit />} />
                <Route exact path='/CityAdd/:country/:state' element={<CityAdd />} />
                <Route exact path='/CityEdit/:id' element={<CityEdit />} />
                <Route exact path='/Pincodelist' element={<Pincodelist />} />
                <Route exact path='/ImportPincode' element={<ImportPincode />} />
                <Route exact path='/PincodeAdd' element={<PincodeAdd />} />
                <Route exact path='/PincodeEdit/:id' element={<PincodeEdit />} />
                <Route exact path='/ShipmentReport' element={<ShipmentReport />} />
                <Route exact path='/PaymentReport' element={<PaymentReport />} />
                <Route exact path='/ClientSalesReport' element={<ClientSalesReport />} />
                <Route exact path='/HubReport' element={<HubReport />} />
                <Route exact path='/AgentReport' element={<AgentReport />} />
                <Route exact path='/DriverReport' element={<DriverReport />} />
                <Route exact path='/CODReport' element={<CODReport />} />
                <Route exact path='/Stocklist' element={<Stocklist />} />
                <Route exact path='/CustomerAdd' element={<CustomerAdd />} />
                <Route exact path='/CustomerEdit/:UniqueId' element={<CustomerEdit />} />
                <Route exact path='/CustomerServices/:type/:UniqueId' element={<CustomerServices />} />
                <Route exact path='/CustomerPricing/:sid/:type/:UniqueId' element={<CustomerPricing />} />
                <Route exact path='/CustomerShipments/:type/:UniqueId' element={<CustomerShipments />} />
                <Route exact path='/CustomerChangePassword/:UniqueId' element={<CustomerChangePassword />} />
                <Route exact path='/AgentAdd' element={<AgentAdd />} />
                <Route exact path='/AgentDetail/:UniqueId' element={<AgentDetail />} />
                <Route exact path='/AgentEdit/:UniqueId' element={<AgentEdit />} />
                <Route exact path='/AgentChangePassword/:UniqueId' element={<AgentChangePassword />} />
                <Route exact path='/AgentShipments/:type/:UniqueId' element={<AgentShipments />} />
                <Route exact path='/AgentServices/:type/:UniqueId' element={<AgentServices />} />
                <Route exact path='/AgentPricing/:sid/:type/:UniqueId' element={<AgentPricing />} />
                <Route exact path='/Weightdiscrepancy' element={<Weightdiscrepancy />} />
                <Route exact path='/ForwardShipmentManifestList' element={<ForwardShipmentManifestList />} />
                <Route exact path='/AddForwardShipmentManifest' element={<AddForwardShipmentManifest />} />
                <Route exact path='/ForwardManidest_print/:slipno' element={<ForwardManidest_print />} />
                <Route exact path='/BulkForwardShipment' element={<BulkForwardShipment />} />
              </Routes>
            </Router>
      </CookiesProvider>
    );
  }
}

export default App;
