import React, { useState } from "react";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Header from "../include/header";
import Footer from "../include/footer";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";


function ServiceAdd() {
    let navigate = useNavigate();
    const [show, SetShow] = useState('close');
    const [servicename, setServicename] = useState('');
    const [servicetype, setServicetype] = useState('Forward');
    const [producttype, setProducttype] = useState('Document');
    const [transportationmode, setTransportationmode] = useState('Surface');
    const [servicemode, setServicemode] = useState('Domestic');
    const [servicebound, setServicebound] = useState('OUT');
    const [inputs, setInputs] = useState([{startrange:"",endrange:""}]);
    const [additionalweight, setAdditionalweight] = useState('');
    const [volumaticweight, setVolumaticweight] = useState('');
    const [dimension, setDimension] = useState('N');
    const [maxlength, setMaxlength] = useState('');
    const [maxheight, setMaxheight] = useState('');
    const [maxwidth, setMaxwidth] = useState('');
    const [maxweight, setMaxweight] = useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(false);

    const addInput = () => {
        let lastrange;
          if (Number.isInteger(inputs[inputs.length - 1].endrange)) {
            lastrange = parseFloat((parseInt(inputs[inputs.length - 1].endrange) + 0.01).toFixed(2));
          } else {
            lastrange = parseFloat((parseFloat(inputs[inputs.length - 1].endrange) + 0.01).toFixed(2));
          }
        setInputs([...inputs,{startrange:lastrange,endrange:""}]);
      };

    const handlechange=(e,i)=>{
        const {name,value} = e.target
        const onchangeVal = [...inputs]
        onchangeVal[i][name]=value
        setInputs(onchangeVal);
    }

    const handleDelete=(i)=>{
        const deleteVal = [...inputs]
        deleteVal.splice(i,1)
        setInputs(deleteVal);
    }

    const handleSubmit=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'CreateService',
            headers:headers1,
            data: {
                services_name: servicename,
                servicetype: servicetype,
                producttype: producttype,
                transportationmode: transportationmode,
                servicemode: servicemode,
                servicebound: servicebound,
                range: inputs,
                additionalweight: additionalweight,
                Volumatic_weight: volumaticweight,
                dimension: dimension,
                dimesion_length: maxlength,
                dimesion_height: maxheight,
                dimesion_width: maxwidth,
                dimesion_weight: maxweight

            }
          })
          .then(function (response) {
            console.log("/////////////",response.data);
            if(response.data.status == 200)
            {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/ServiceList/All'); 
                }, 1000);
            }
            else
            {
                if(response.data.message.services_name)
                {
                    toast.warn(response.data.message.services_name[0]);
                }
                else if(response.data.message.Volumatic_weight)
                {
                    toast.warn(response.data.message.Volumatic_weight[0]);
                }
                else if(response.data.message.dimesion_length)
                {
                    toast.warn(response.data.message.dimesion_length[0]);
                }
                else if(response.data.message.dimesion_height)
                {
                    toast.warn(response.data.message.dimesion_height[0]);
                }
                else if(response.data.message.dimesion_width)
                {
                    toast.warn(response.data.message.dimesion_width[0]);
                }
                else if(response.data.message.dimesion_weight)
                {
                    toast.warn(response.data.message.dimesion_weight[0]);
                }
                else{

                }
                
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Services</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Create Service</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Service Name <small className="text-danger">*</small> </label>
                                        <input type="text" className="form-control" id="" placeholder="Please enter Service name" value={servicename} onChange={(e)=>setServicename(e.target.value)} />
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Service Type <small className="text-danger">*</small></label>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" id="Forward" value="Forward" checked={servicetype === 'Forward'} onChange={(e)=>setServicetype(e.target.value)} />
                                                <label className="form-check-label" for="Forward">Forward</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" id="Reverse" value="Reverse" checked={servicetype === 'Reverse'} onChange={(e)=>setServicetype(e.target.value)} />
                                                <label className="form-check-label" for="Reverse">Reverse</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Product Type <small className="text-danger">*</small></label>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" value="Document" id="Document" checked={producttype === 'Document'} onChange={(e)=>setProducttype(e.target.value)} />
                                                <label className="form-check-label" for="">Document</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" value="Parcel" id="Parcel" checked={producttype === 'Parcel'} onChange={(e)=>setProducttype(e.target.value)} />
                                                <label className="form-check-label" for="Parcel">Parcel</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Transportation Mode <small className="text-danger">*</small></label>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" value="Surface" id="Surface" checked={transportationmode === 'Surface'} onChange={(e)=>setTransportationmode(e.target.value)} />
                                                <label className="form-check-label" for="Surface">Surface</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" value="Air" id="Air" checked={transportationmode === 'Air'} onChange={(e)=>setTransportationmode(e.target.value)} />
                                                <label className="form-check-label" for="Air">Air</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" value="Sea" id="Sea" checked={transportationmode === 'Sea'} onChange={(e)=>setTransportationmode(e.target.value)} />
                                                <label className="form-check-label" for="Sea">Sea</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Service Mode <small className="text-danger">*</small></label>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" value="Domestic" id="Domestic" checked={servicemode === 'Domestic'} onChange={(e)=>setServicemode(e.target.value)} />
                                                <label className="form-check-label" for="Domestic">Domestic</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" value="International" id="International" checked={servicemode === 'International'} onChange={(e)=>setServicemode(e.target.value)} />
                                                <label className="form-check-label" for="International">International</label>
                                            </div>
                                        </div>
                                        {servicemode =='Internatinoal' &&
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" id="IN" value="IN" checked={servicebound == 'IN'} onChange={(e)=>setServicebound(e.target.value)} />
                                                <label className="form-check-label" for="IN">In Bound International</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" id="OUT" value="OUT" checked={servicebound == 'OUT'} onChange={(e)=>setServicebound(e.target.value)}/>
                                                <label className="form-check-label" for="OUT">Out Bound Internatinoal</label>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-12 mb-3 mt-4">
                                        <strong className="py-3">Weight Range <small className="text-danger">*</small></strong>
                                        <div className="mt-2">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Start Range (KG)</th>
                                                        <th>End Range (KG)</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {inputs.map((val, i) => (
                                                    <tr key={i}>
                                                        <td>{i+1}</td>
                                                        <td>
                                                            {i ===0 ?
                                                                <input type="number" name="startrange" className="form-control" value="0.00" readOnly placeholder="Enter weight in number" onChange={(e)=>handlechange(e,i)} />
                                                            :
                                                            <input type="number" name="startrange" className="form-control" value={val.startrange} placeholder="Enter weight in number" onChange={(e)=>handlechange(e,i)} />
                                                            }
                                                        </td>
                                                        <td>
                                                            <input type="number" name="endrange" className="form-control" value={val.endrange} placeholder="Enter weight in number" onChange={(e)=>handlechange(e,i)} />
                                                        </td>
                                                        <td>
                                                            {i ===0 ?
                                                            <button className="btn btn-primary" onClick={addInput}><i className="bx bx-plus"></i></button>
                                                            :
                                                            <button className="btn btn-primary" onClick={()=>handleDelete(i)}><svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24"><path fill="currentColor" d="M5 11v2h14v-2z"/></svg></button>
                                                            }
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3 mt-4">
                                        <strong className="py-3">Additional Weight</strong>
                                        <div className="mt-2">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Additional Weight (KG)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="number" className="form-control" value={additionalweight} onChange={(e)=>setAdditionalweight(e.target.value)} placeholder="Enter weight in number" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-8 mb-3 mt-4">
                                        <strong className="py-3">Volumatic Weight Setup <small className="text-danger">*</small></strong>
                                        <div className="mt-2">
                                            <label for="" className="form-label">Volumatic Weight Calculation by</label>
                                            <div className="input-group">
                                                <span className="input-group-text">length x width x height / by</span>
                                                <input type="number" className="form-control" placeholder="Please enter Volumatic Weight Value" value={volumaticweight} onChange={(e)=>setVolumaticweight(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3 mt-4">
                                        <strong>Dimension Setup <small className="text-danger">*</small></strong>
                                        <div>
                                            <div>
                                                <div className="form-check form-switch  mb-2">
                                                    <input className="form-check-input" type="checkbox" role="switch" checked={dimension=='Y'? true : false} onChange={()=>{dimension=='Y'? setDimension('N'):setDimension('Y')}} />
                                                    <label className="form-check-label small" for="flexSwitchCheckChecked">Do You want to Set Dimension</label>
                                                </div>
                                            </div>
                                        </div>
                                        {dimension=='Y' &&
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Maximum Length</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Please enter value" value={maxlength} onChange={(e)=>setMaxlength(e.target.value)}/>
                                                    <span className="input-group-text">CM</span>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Maximum Height</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Please enter value" value={maxheight} onChange={(e)=>setMaxheight(e.target.value)}/>
                                                    <span className="input-group-text">CM</span>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Maximum Width</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Please enter value" value={maxwidth} onChange={(e)=>setMaxwidth(e.target.value)}/>
                                                    <span className="input-group-text">CM</span>
                                                </div>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Maximum Weight</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Please enter value" value={maxweight} onChange={(e)=>setMaxweight(e.target.value)}/>
                                                    <span className="input-group-text">KG</span>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                {Loading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handleSubmit()}>Create Service</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <ul className="service_list">
                            <li>
                                <strong>Service Name</strong>:
                                <br />
                                <em>Description:</em> Please enter the name of the service.
                                <br />
                                <em>Example:</em> "Express Shipping", "Document Verification", etc.
                            </li>
                            <li>
                                <strong>Product Type</strong>:
                                <br />
                                <em>Description:</em> Select the type of product or service.
                                <br />
                                <em>Options:</em> "Document" or "Parcel".
                            </li>
                            <li>
                                <strong>Service Type</strong>:
                                <br />
                                <em>Description:</em> Specify if the service is for forwarding or reverse logistics.
                                <br />
                                <em>Options:</em> "Forward" or "Reverse".
                            </li>
                            <li>
                                <strong>Transportation Mode</strong>:
                                <br />
                                <em>Description:</em> Select the mode of transportation.
                                <br />
                                <em>Options:</em> "Surface", "Air", or "Sea".
                            </li>
                            <li>
                                <strong>Service Mode</strong>:
                                <br />
                                <em>Description:</em> Select whether the service is domestic or international.
                                <br />
                                <em>Options:</em> "Domestic" or "International".
                                <br />
                                <em>For International:</em> Choose "Inbound" or "Outbound".
                            </li>
                            <li>
                                <strong>Weight Range</strong>:
                                <br />
                                <em>Description:</em> Define weight ranges applicable to the service.
                                <br />
                                <em>Example:</em> Range 1: 0-5 kg, Range 2: 5-10 kg, etc.
                            </li>
                            <li>
                                <strong>Additional Weight</strong>:
                                <br />
                                <em>Description:</em> Specify additional weight options if applicable.
                                <br />
                                <em>Example:</em> Extra charges for weight exceeding the specified range.
                            </li>
                            <li>
                                <strong>Volumetric Weight Setup</strong>:
                                <br/>
                                <em>Description:</em> Choose the method for calculating volumetric weight.
                                <br/>
                                <em>Options:</em> "Length x Width x Height" or "Other specified formula".
                            </li>
                            <li>
                                <strong>Volumetric Weight Calculation by</strong>:
                                <br/>
                                <em>Description:</em> Specify the formula for volumetric weight calculation.
                                <br/>
                                <em>Example:</em> "Length x Width x Height" or "Custom formula".
                            </li>
                            <li>
                                <strong>Volumetric Weight Value</strong>:
                                <br />
                                <em>Description:</em> Enter the value of the volumetric weight.
                            </li>
                            <li>
                                <strong>Dimension Setup</strong>:
                                <br/>
                                <em>Description:</em> Configure maximum dimensions allowed for shipments.
                                <br/>
                                <em>Example:</em> Maximum Length: [value] cm, Maximum Height: [value] cm, Maximum Width: [value] cm.
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </main>
        </>
        );

}
export default ServiceAdd;