import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";

function Orderinvoice() {
    let navigate = useNavigate();
    const { slipno } =useParams();
    const [shipmentdata, setShipmentdata] = useState([]);
    const [productdetailarray, setProductdetailarray] = useState([]);
    const { headers1, headers2, logout } = AuthUser();
    useEffect(() => {
        GetInvoice();
    }, []);
    const GetInvoice = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'ShipmentInvoice',
            headers: headers1,
            data: {
                slip_no: slipno
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setShipmentdata(data);
                    setProductdetailarray(data.productdetailarray)
                }
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
    }

    return (
            <div class="container mt-3 wrap">
                <div class="card mb-1">
                    <div class="card-body">
                    <div class="text-center MB-3">
                        <h3>TAX INVOICE</h3>
                    </div>
                    <div class="text-end">
                        <h4>Invoice</h4>
                        <p>Invoice Number - {shipmentdata.invoice_no}</p>
                    </div>
                    <hr />
                    <table class="table">
                        <tbody>
                            <tr>
                            <td style={{"width": "40%"}}>
                                <div class="mb-3">
                                <h4>Bill To:</h4>
                                {shipmentdata.bill_to =='S' ?
                                <p>
                                <strong>{shipmentdata.sender_name}</strong><br />
                                        {shipmentdata.sender_address}
                                </p>
                                :
                                <p>
                                <strong>{shipmentdata.reciever_name}</strong><br />
                                        {shipmentdata.reciever_address}
                                </p>
                                }
                                </div>
                                <div class="mb-3">
                                <h4>Ship To:</h4>
                                <>
                                {shipmentdata.bill_to !='S' ?
                                <p>
                                <strong>{shipmentdata.sender_name}</strong><br />
                                        {shipmentdata.sender_address}
                                </p>
                                :
                                <p>
                                <strong>{shipmentdata.reciever_name}</strong><br />
                                        {shipmentdata.reciever_address}
                                </p>
                                }
                                </>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            
                            </tr>
                        </tbody>
                        </table>

                        <table class="table">
                            <tbody>
                            <tr>
                                <td>
                                <div>
                                    <strong>Payment Method:</strong>
                                    {shipmentdata.booking_mode}
                                </div>
                                <div>
                                    <strong>AWB No:</strong>
                                    {shipmentdata.slip_no}
                                </div>
                                </td>
                                <td></td>
                                <td class="text-end">
                                <div>
                                    <strong>Order Date:</strong>
                                    {shipmentdata.entrydate}
                                </div>
                                {/* <div>
                                    <strong>Shipped By:</strong>
                                    DTDC
                                </div> */}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div> 

                <div class="card mb-1">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="">
                            <tr>
                                <th>Product Name</th>
                                <th>QTY</th>
                                <th>Price</th>
                            </tr>
                            </thead>
                            <tbody>
                                {productdetailarray.map((pd,i)=>{
                                return(
                                    <tr>
                                        <td><strong>{pd.product_name}</strong></td> 
                                        <td>{pd.product_qty}</td>
                                        <td>{shipmentdata.gDefaultCurrency} {pd.product_price}</td>
                                        </tr>
                                            );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
                <div class="card mb-1">
                    <div class="card-body">
                    <div class="row justify-content-end">
                        <div class="col-8">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Charges Applied</th>
                                        <th>Tax Amount</th>
                                        <th>IGST Value(%)</th>
                                        <th>Total (Including GST)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        </tr>
                                        
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td class="fw-bold">Total Amount</td>
                                        <td>{shipmentdata.gDefaultCurrency} {shipmentdata.productpricesum}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
      );       
}
export default Orderinvoice;