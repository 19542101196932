import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AuthUser from "../../AuthUser";

function ShipmentUpdateStatus(){
    let navigate = useNavigate();
    let {slipno} = useParams();
    const [countrylist, setCountrylist] = useState([]);
    const [citylist, setCitylist] = useState([]);
    const [hublist, setHublist] = useState([]);
    const [routecodelist, setRoutecodelist] = useState([]);
    const [messengerlist, setMessengerlist] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedRouteCode, setSelectedRouteCode] = useState('');
    const [selectedMessenger, setSelectedMessenger] = useState('');
    const [selectedHub, setSelectedHub] = useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [todaydate, setTodayDate] = useState(getTodayDateString());
    const [currentTime, setCurrentTime] = useState(new Date());
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const [status, SetStatus] = useState('');
    const [userdata, setUserdata] = useState('');
    const [activities, setActivities] = useState('');
    const [details, setDetails] = useState('');
    const [reason, setReason] = useState('');
    const [receivername, setReceivername] = useState('');
    const [receivermobileno, setReceivermobileno] = useState('');
    const [setloader, setSetloader] = useState(false);

    useEffect(()=>{
        GetCountrylist();
          const items = JSON.parse(localStorage.getItem('user'));
            if (items) {
            setUserdata(items);
            }
            const timerId = setInterval(() => {
                setCurrentTime(new Date());
            }, 10000);
            return () => clearInterval(timerId);
    },[]);

    const handleTimeChange = (e) => {
        const [hours, minutes] = e.target.value.split(':');
        const newTime = new Date(currentTime);
        newTime.setHours(hours, minutes);
        setCurrentTime(newTime);
    };

    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    
    // Handle country selection
    const handleCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedCountry(event.target.value);
        GetCityList(countryname);
    };

    // Handle city selection
    const handleCityChange = (event) => {
        const cityid = event.target.value;
        setSelectedCity(cityid);
        GetHubList(cityid);
        GetRouteCodeList(cityid);
        GetMessengerList(cityid);
    };

   

    // Handle Hub selection
    const handleMessengerChange = (event) => {
        setSelectedMessenger(event.target.value);
    };

   
    const GetMessengerList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Messengerlist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setMessengerlist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetHubList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Hublist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setHublist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetRouteCodeList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Routecodelist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setRoutecodelist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const handleHubChange = (event) => {
        setSelectedHub(event.target.value);
    };

    const handleRouteCodeChange = (event) => {
        setSelectedRouteCode(event.target.value);
    };
   
   const Submitstatus=()=>
    {
        if(status =='')
        {
            toast.warn('Please Select Status!');
        }
        else if(selectedCountry =='')
        {
            toast.warn('Please Select Country!');
        }
        else if(selectedCity =='')
        {
            toast.warn('Please Select City!');
        }
        else if(selectedHub == '' && (status =='T' || status =='D' || status =='IT'))
        {
            toast.warn('Please Select Hub!');
        }
        else if(selectedRouteCode == '' && (status =='T' || status =='D'))
        {
            toast.warn('Please Select Route Code!');
        }
        else if(selectedMessenger == '' && (status =='T' || status =='D'))
        {
            toast.warn('Please Select Messenger!');
        }
        else if(receivername == '' && status =='D')
        {
            toast.warn('Please Enter Receiver Name!');
        }
        else if(receivermobileno == '' && status =='D')
        {
            toast.warn('Please Enter Receiver Mobile No!');
        }
        else if(reason == '' && (status =='R' || status =='DL' || status =='RJ' || status =='C'))
        {
            toast.warn('Please Select Reason!');
        }
        else if(activities == '' && (status =='DL' || status =='RJ' || status =='C'))
        {
            toast.warn('Please Enter Activities!');
        }
        else if(details == '' && (status =='DL' || status =='RJ' || status =='C'))
        {
            toast.warn('Please Enter Details!');
        }
        else
        {
            setSetloader(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'ShipmentStatusupdate',
                headers:headers1,
                data: {
                    userid: userdata.id,
                    usertype: userdata.usertype,
                    slipno: slipno,
                    hub_id: selectedHub,
                    routecode: selectedRouteCode,
                    messengerid: selectedMessenger,
                    status: status,
                    cityid: selectedCity,
                    date: todaydate,
                    time: formattedTime,
                    activities: activities,
                    details: details,
                    reason: reason,
                    receivername: receivername,
                    receivermobileno: receivermobileno
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setSetloader(false);
                    setTimeout(() => {
                        navigate('/Shipmentlist/All');
                      }, 1000);
                }
                else{
                    toast.success(response.data.error);
                    setSetloader(false);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Shipment Status Update</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Shipment Status Update Tracking No : {slipno}</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select New Status: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={(e)=>SetStatus(e.target.value)}>
                          <option value="">-- Please Select --</option>
                          <option value="T">Picked up</option>
                          <option value="IT">In Transit</option>
                          <option value="R">Returned</option>
                          {/* <option value="DL">Reschedule</option> */}
                          <option value="RJ">Rejected</option>
                          <option value="D">Delivered</option>
                          <option value="C">Cancel</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Country: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={handleCountryChange}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list)=>{
                            return (
                            <option value={list.country}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select City: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={(e)=>handleCityChange(e)}>
                          <option value="">-- Please Select --</option>
                          {citylist.map((list)=>{
                            return (
                            <option value={list.value}>{list.label}</option>
                            );
                            })}
                        </select>
                    </div>
                    { status =='T' || status =='D' || status =='IT' ?
                    <>
                    <div className="col-12 col-md-4 mb-3">
                    <div className="d-flex justify-content-between">
                        <div ><label for="email" className="form-label">Select Hub: <span className="text-danger">*</span></label> </div>
                        <div><small className=""><a href="/HubAdd" className="text-muted" target="_blank">Add New HUB</a></small></div>
                    </div>
                    <select className="form-select" onChange={handleHubChange}>
                      <option value="">-- Please Select --</option>
                      {hublist.map((list)=>{
                        return (
                        <option value={list.value}>{list.label}</option>
                        );
                        })}
                    </select>
                </div>
                </>
                :
                ""
                    }
                { status =='T' || status =='D' ?
                <>
                    <div className="col-12 col-md-4 mb-3">
                    <div className="d-flex justify-content-between">
                        <div ><label for="email" className="form-label">Select City Route Code: <span className="text-danger">*</span></label> </div>
                        <div><small className=""><a href="/RouteAdd" target="_blank" className="text-muted">Add New Route Code</a></small></div>
                    </div>
                    <select className="form-select" onChange={(e)=>handleRouteCodeChange(e)}>
                      <option value="">-- Please Select --</option>
                      {routecodelist.map((list)=>{
                        return (
                        <option value={list.value}>{list.label}</option>
                        );
                        })}
                    </select>
                </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select Messenger (Driver): <span className="text-danger">*</span></label> </div>
                            <div><small className=""><a href="/DriverAdd" target="_blank" className="text-muted">Add New Driver</a></small></div>
                        </div>
                        <select className="form-select" onChange={(e)=>handleMessengerChange(e)}>
                          <option value="">-- Please Select --</option>
                          {messengerlist.map((list)=>{
                            return (
                            <option value={list.value}>{list.label}</option>
                            );
                            })}
                        </select>
                    </div>
                    </>
                    :
                    ""
                    }
                    <div className="col-12 col-md-4 mb-3">
                        <label for="email" className="form-label">Date:</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="bx bx-calendar"></i></span>
                            <input type="date" className="form-control" placeholder="Date" value={todaydate} onChange={(e)=>setTodayDate(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <label for="email" className="form-label">Time:</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="bx bx-time"></i></span>
                            <input type="time" className="form-control" placeholder="Time" value={formattedTime} onChange={handleTimeChange} />
                        </div>
                    </div>
                    { status =='R' || status =='DL' || status =='RJ' || status =='C' ?
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Reason: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={(e)=>setReason(e.target.value)}>
                          <option value="">-- Please Select --</option>
                          <option value="Premises Locked">Premises Locked</option>
                          <option value="Consignee Not Avilable">Consignee Not Avilable</option>
                          <option value="Missroute">Misroute</option>
                          <option value="Uncertain Reasons">Uncertain Reasons</option>
                        </select>
                    </div>
                    :
                    ""
                    }  
                    { status == 'D' && 
                    <>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Receiver Name: <span className="text-danger">*</span></label></div>
                        <input type="text" className="form-control" onChange={(e)=>setReceivername(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Receiver Mobile No: <span className="text-danger">*</span></label></div>
                        <input type="number" className="form-control" onChange={(e)=>setReceivermobileno(e.target.value)}/>
                    </div>
                    </>
                    }
                    { status =='DL' || status =='RJ' || status =='C' ?
                    <>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Activities: <span className="text-danger">*</span></label></div>
                        <input type="text" className="form-control" onChange={(e)=>setActivities(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Details: <span className="text-danger">*</span></label></div>
                        <input type="text" className="form-control" onChange={(e)=>setDetails(e.target.value)}/>
                    </div>
                    </>
                    :
                    ""
                    }
                    <div className="py-3">
                    {setloader == false ?
                        <button className="btn btn-primary" onClick={()=>Submitstatus()}>Update Status</button>
                        :
                        <button className="btn btn-primary btn-lg btn-block disabled"><span className="spinner"></span></button>
                    }
                    </div>
                </div>
              </div>
            </div>
            <Footer/>
        </main>
        </>

    );
}
export default ShipmentUpdateStatus;