import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import Global from "../../APIConfig";
import  axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

function BagManifestList(){
    let navigate = useNavigate();
    const {type} = useParams();
    const [list, setList] = useState([]);
    const [runningbagmanifest, setRunningbagmanifest] = useState('');
    const [complatedbagmanifest, setComplatedbagmanifest] = useState('');
    const [searchtype, setSearchtype] = useState('Any');
    const [searchvalue, setSearchvalue] = useState('');
    const [showpage, setShowpage] = useState(type);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true); 
    const [SubLoading, setSubLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    
    useEffect(()=>{
        GetRunningBagManifestList(type,'');
        GetTotalRunningBagManifest();
        GetTotalComplatedBagManifest();
    },[]);

    const userdata = JSON.parse(localStorage.getItem('user'));

    const GetRunningBagManifestList=(type,val)=>{
        setSubLoading(true);
        setShowpage(type);
        setSearchvalue(val);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetRunningBagManifestList',
            headers:headers1,
            data: {
                usertype:userdata.usertype,
                pagetype:type,
                cityid:userdata.branch_location,
                searchtype: searchtype,
                searchvalue: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
            }
            else
            {
                setList();
            }
            setSubLoading(false);
          })
          .catch(function (error) {
            setSubLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    const GetTotalRunningBagManifest=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetTotalRunningBagManifest',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                setRunningbagmanifest(response.data.value)
            }
            else
            {
                setRunningbagmanifest(response.data.value);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const GetTotalComplatedBagManifest=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetTotalComplatedBagManifest',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                setComplatedbagmanifest(response.data.value)
            }
            else
            {
                setComplatedbagmanifest(response.data.value);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const handleChange = (event) => {
        const uniqueid = event.target.value;
        if(!checkedItems.includes(uniqueid)){
            setCheckedItems([...checkedItems,uniqueid]);
        }else{
            let arr=checkedItems.filter((item)=>item!==uniqueid);
            setCheckedItems(arr);
        }
        
      };

      const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = list.map(item => item.uniqueid);
            setCheckedItems(allSlipNos);
        } else {
            setCheckedItems([]);
        }
    };
   
    const isChecked = (uniqueid) => checkedItems.includes(uniqueid);

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 mb-3">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Manifest Management</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">All Manifests</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="dropdown">
                        <button className="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <a className="dropdown-item d-flex align-items-center" href="/AddBagManifest">
                                <i className="bx bx-cube-alt text-primary-400 me-2 fs-5"></i>
                                Create a New Bag Manifest
                            </a>
                        </div>
                    </div>
                    {/* <div className="btn-group ms-2 ms-lg-3">
                        <button type="button" className="btn btn-sm btn-outline-gray-600" data-bs-toggle="tooltip" title="Print Label"><i className="bx bx-printer"></i> Print Label</button>
                    </div> */}
                </div>
            </div>

            <section className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <div className="card border-0 shadow">
                            <div className="card-body">
                                <div className="row d-block d-xl-flex align-items-center">
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                            <i className="bx bx-time"></i>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="d-none d-sm-block">
                                            <h2 className="h6 text-gray-400 mb-0"> Running Manifests</h2>
                                            <h3 className="fw-extrabold mb-2"><a href="">{runningbagmanifest}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="card border-0 shadow">
                            <div className="card-body">
                                <div className="row d-block d-xl-flex align-items-center">
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                            <i className="bx bx-check-double"></i>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="d-none d-sm-block">
                                            <h2 className="h6 text-gray-400 mb-0"> Completed Manifests</h2>
                                            <h3 className="fw-extrabold mb-2"><a href="">{complatedbagmanifest}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row">
                <div className="col-12 col-md">
                    <div className="custom_nav_data_table">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <a className={showpage == 'Running' ? "nav-link active":'nav-link'} value="Running" href="/BagManifestList/Running">Running Manifests</a>
                            </li>
                            <li className="nav-item">
                              <a className={showpage == 'Completed' ? "nav-link active":'nav-link'} value="Completed" href="/BagManifestList/Completed">Completed Manifests</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="table-settings shipment_list" id="">
                        <div className="row align-items-start g-2" >
                            <div className="col col-md ">
                                <div className="input-group ">
                                    <select className="form-select" onChange={(e)=> setSearchtype(e.target.value)}>
                                      <option value="Any">Any</option>
                                      <option value="Bag">Bag #</option>
                                      <option value="AWB">AWB #</option>
                                    </select>
                                    <input type="text" className="form-control w-50" placeholder="Search" value={searchvalue} onChange={(e)=>GetRunningBagManifestList(showpage,e.target.value)} />
                                    <span className="input-group-text">
                                        <i className="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                {list !=undefined ?
                                    <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange}  checked={checkedItems.length === list.length}/>
                                :
                                ''
                                }
                                </th>						
                                <th className="border-gray-200">Manifest ID#</th>
                                <th className="border-gray-200">Bag ID#</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Source</th>
                                <th className="border-gray-200">Destination</th>
                                <th className="border-gray-200">No. of Bags</th>
                                <th className="border-gray-200">No. of Shipments</th>
                                <th className="border-gray-200">Total Weight</th>
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        {SubLoading==true? <tbody><tr><td colSpan={9}><ListLoader/></td></tr></tbody> : 
                        <tbody>
                        {list != undefined ? 
                             <>
                             {list.map((mani,index)=>{
                                 return(
                            <tr key={index}>
                                <td style={{"width": "20px"}}>
                                    <input className="form-check-input" type="checkbox" id={index} value={mani.uniqueid} checked={isChecked(mani.uniqueid)} onChange={handleChange} />
                                </td>
                                <td>
                                    <div>
                                        <Link to={`/BagManifestDetails/${mani.uniqueid}`} className="fw-bold">{mani.uniqueid}</Link>
                                        <CopyToClipboard text={mani.uniqueid} onCopy={()=>{toast.success(mani.uniqueid+' Copied To Clipboard')}}>
                                            <i className="bx bx-copy"></i>
                                        </CopyToClipboard>
                                    </div>
                                </td>
                                <td>
                                    <span className="fw-normal text-capitalize">
                                        {mani.Totalbagids.map((bag,order)=>{
                                            return (
                                        <>
                                        <Link to={`/BagDetails/${bag}`}>{bag}</Link><br />
                                        </>
                                        );
                                            }
                                        )}
                                        </span>
                                </td>
                                <td><span className="fw-normal text-capitalize"><small>{mani.mdate}</small></span></td>
                                <td><span className="fw-normal text-capitalize">{mani.sendercity}, {mani.sendercountry}</span></td>
                                <td><span className="fw-normal text-capitalize">{mani.receivercity}, {mani.receivercountry}</span></td>
                                <td><span className="fw-normal text-capitalize">{mani.Totalbagcount}</span></td>
                                <td><span className="fw-normal text-capitalize">{mani.Totalshipmentcount}</span></td>
                                <td><span className="fw-normal text-capitalize">{mani.Totalweightcount}KG</span></td>
                                <td>
                                    {mani.arrived =='N'?
                                    <span className="status running"> <i className="bx bx-time"></i> Running</span>
                                    :
                                    <span class="status completed"> <i class="bx bx-check-double"></i> Completed</span>
                                    }
                                </td>
                                <td>
                                    {/* <a href=".php" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Download Manifest Label"><i className="bx bx-barcode"></i></a> */}
                                    <Link to={`/Manidest_print/${mani.uniqueid}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Print Manifest List"><i className="bx bx-printer"></i></Link>
                                    <Link to={`/BagManifestDetails/${mani.uniqueid}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="View Manifest"><i className="bx bx-file"></i></Link>
                                </td>
                            </tr>
                                );
                            })}   
                        </>
                            :
                            <tr>
                               <td colSpan="11" className="text-center text-danger">Record Not Found</td>
                            </tr>
                           }                       
                        </tbody>}
                    </table>
                    
                </div>
            </section>
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default BagManifestList;