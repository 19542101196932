import { useState, useEffect } from 'react';
import '../../ConsignmentNote.css';
import axios from "axios";
import Global from "../../APIConfig";
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";

function ForwardManidest_print(){
    let navigate = useNavigate();
  const {slipno} = useParams();
  const [total_amt, setTotal_amt] = useState('');
  const [sendercustomername, setSendercustomername] = useState('');
  const [sendermobilenumber, setSendermobilenumber] = useState('');
  const [total_cod_amt, setTotal_cod_amt] = useState('');
  const [senderAddress, setSenderAddress] = useState('');
  const [senderPostalcode, setSenderPostalcode] = useState('');
  const [sendercountryname, setSendercountryname] = useState('');
  const [senderState, setSenderState] = useState('');
  const [sendercityName, setSendercityName] = useState('');
  const [routecode, setRoutecode] = useState('No');
  const {headers1,headers2,logout}=AuthUser();
  const [gDefaultCurrency,setGDefaultCurrency] = useState('');
  const [printlogo,setPrintlogo] = useState('');
  const [booking_mode, setBooking_mode] = useState('');
  const [shipmentid, setShipmentid] = useState('');
  const [manicount, setManicount] = useState('');
  const [frwd, setFrwd] = useState('');
  const [mdate, setmdate] = useState('');
    useEffect(() => {
      GetShipmentdata();
    }, []);
    const GetShipmentdata=()=>{
      axios({
          method: 'post',
          url: Global.baseUrl+'ForwardPrintManifest',
          headers:headers1,
          data: {
            uniqueid: slipno
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
              var data=response.data.value;
              setSendercustomername(data.sender_name);
              setSendermobilenumber(data.sender_phone);
              setSenderAddress(data.sender_address);
              setSenderPostalcode(data.sender_zip);
              setSendercountryname(data.country);
              setSenderState(data.state)
              setSendercityName(data.city);
              setGDefaultCurrency(data.gDefaultCurrency);
              setPrintlogo(data.printlogo);
              setRoutecode(data.routecode);
              setTotal_cod_amt(data.total_cod_amt);
              setTotal_amt(data.total_amt);
              setBooking_mode(data.booking_mode);
              setShipmentid(data.slip_no);
              setManicount(data.manicount);
              setFrwd(data.frwd_throw);
              setmdate(data.mdate);
          }
        })
        .catch(function (error) {
          if(error.response.status==401){
              toast.error(error.response.statusText);
              logout();
              navigate('/');
              window.location.reload();
          }
          // console.log(error);
      });
    }
return(
        <div class="container mt-3">
        <div class="row justify-content-between">
            <div class="col col-md-4">
                <h2><img src={`${Global.logoUrl}${printlogo}`} className="img-fluid" /></h2>
            </div>
            <div class="col col-md text-center">
                <h2 class="m-0 p-0">Manifest</h2>
                <div class="d-flex justify-content-center">
                    <div class="p-1">Generated on {mdate}</div>
                </div>
            </div>
            <div class="col-3 col-md-3 text-end">
                <img src="https://i.imgur.com/Sx6F4aC.png" class="img-fluid" style={{"max-height":"40px"}} />
                <h5>MID: {slipno}</h5>
            </div>
        </div>
        <table className="table table-bordered border-dark border-2">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Order #</th>
                {/* <th>AWB #</th> */}
                <th>Carrier</th>
                <th>Content</th>
                <th>Signature/Company Seal</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>
                    <h6>{slipno}</h6>
                </td>
                {/* <td class="text-center">
                    <h6 class="m-0 p-0"><Barcode value={slipno} /></h6>
                </td> */}
                <td>{frwd}</td>
                <td>
                    {senderAddress}
                </td>
                <td>
                    
                </td>
              </tr>
            </tbody>
        </table>

        <hr />
        <div class="row mt-0">
            <div class="col-6 col-md-3">
                Receiver Name:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Receiver Date/Time:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Receiver Signature:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Total Shipments: {manicount}
            </div>
        </div>


    </div>
);
}

export default ForwardManidest_print;