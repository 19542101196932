import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link,useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import CopyToClipboard from 'react-copy-to-clipboard';


function AddForwardShipmentManifest(){
    const navigate = useNavigate();
    const [carrierlist, setCarrierlist] = useState([]);
    const [shipmentlist, setShipmentlist] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);
    const [Btn1Loading, setBtn1Loading] = useState(false);
    const [userdata, setUserdata] = useState('');

    useEffect(()=>{
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
         setUserdata(items);
        }
        GetCarrierlist()
    },[]);
   
     // Handle Hub selection
     const handleCarrierChange = (event) => {
        setSelectedCarrier(event.label);
    };

    const GenrateShipmentManifest=()=>{
        if(checkedItems =='')
        {
            toast.warn('Please Select At Least One Shipment!');
        }
        else
        {
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'AddForwardShipmentManifest',
                headers:headers1,
                data: {
                    usertype:userdata.usertype,
                    userid:userdata.id,
                    slip_no: checkedItems
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/ForwardShipmentManifestList');
                      }, 1000);
                }
                else
                {
                    toast.error(response.data.message);
                }
                setBtnLoading(false);
            })
            .catch(function (error) {
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

    const GetCarrierlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Allcarrierslist',
            headers:headers1,
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCarrierlist(data);
            }
            else
            {
                setCarrierlist();
            }
            setLoading('false');
          })
    }

    const GetShipmets=()=>{
        if(selectedCarrier =='')
        {
            toast.warn('Please Select Carrier!')
        }
        else
        {
            setBtn1Loading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'GetForwardShipmentlist',
                headers:headers1,
                data: {
                    usertype:userdata.usertype,
                    userid:userdata.id,
                    carrier:selectedCarrier
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    var data=response.data.value;
                    setShipmentlist(data);
                }
                else
                {
                    setShipmentlist([]);
                }
                setBtn1Loading(false);
            })
            .catch(function (error) {
                setBtn1Loading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

    const handleChange = (event) => {
        const slipno = event.target.value;
        if(!checkedItems.includes(slipno)){
            setCheckedItems([...checkedItems,slipno]);
        }else{
            let arr=checkedItems.filter((item)=>item!==slipno);
            setCheckedItems(arr);
        }
        
      };

      const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = shipmentlist.map(item => item.slip_no);
            setCheckedItems(allSlipNos);
        } else {
            setCheckedItems([]);
        }
    };
    const handlescan=(e)=>{
        const slip_no = e.target.value;
        let tempShipment = shipmentlist.map((ship) => {
            if (ship.slip_no === slip_no) {
                if (!checkedItems.includes(slip_no)) {
                    setCheckedItems([...checkedItems, slip_no]);
                }
            }
        });
    }
    
    const isChecked = (slip_no) => checkedItems.includes(slip_no);

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ?<MainLoader/>:
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Manifest Management</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Generate Shipment Manifest</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select Carrier: <span className="text-danger">*</span></label> </div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleCarrierChange}
                        options={carrierlist} 
                        placeholder="Select Carrier"
                        />
                    </div>
                    <div className="col-md-12"></div>
                    {Btn1Loading==false?
                    <div className="d-grid col-md-2 mt-2">
                        <button onClick={()=>GetShipmets()} className="btn btn-primary">View Shipment</button>
                    </div>
                    :
                    <div className="d-grid col-md-2 mt-2">
                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                    </div>
                    }
                </div>
              </div>
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                             <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                {shipmentlist !=undefined ?
                                    <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange}  checked={checkedItems.length === shipmentlist.length}/>
                                :
                                ''
                                }
                                </th>						
                                <th className="border-gray-200">AWB No#</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Origin</th>
                                <th className="border-gray-200">Destination</th>
                                <th className="border-gray-200">Sender</th>
                                <th className="border-gray-200">Receiver</th>
                            </tr>
                        </thead>
                        <tbody>
                    {shipmentlist.length>0 ?
                            <>
                        {shipmentlist.map((shi,index) =>{
                                return(
                                <tr key={index}>
                                    <td style={{"width": "20px"}}>
                                        <input className="form-check-input" type="checkbox" id={index} value={shi.slip_no} checked={isChecked(shi.slip_no)} onChange={handleChange} />
                                    </td>
                                    <td>
                                        <div>
                                            <Link target="_blank" to={`/ShipmentDetails/${shi.slip_no}`} className="fw-bold">{shi.slip_no}</Link>
                                            <CopyToClipboard text={shi.slip_no} onCopy={()=>{toast.success(shi.slip_no+' Copied To Clipboard')}}>
                                                <i className="bx bx-copy"></i>
                                            </CopyToClipboard>
                                        </div>
                                    </td>
                                    <td><span className="fw-normal text-capitalize"><small>{shi.entrydate}</small></span></td>
                                    <td><span className="fw-normal text-capitalize">{shi.sendercity}, {shi.sendercountry}</span></td>
                                    <td>
                                        <span className="fw-normal text-capitalize">{shi.receivercity}, {shi.receivercountry}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal text-capitalize">{shi.sender_name}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal text-capitalize">{shi.reciever_name}</span>
                                    </td>
                                </tr>
                                );
                            })} 
                        </> 
                        :
                        <tr>
                            <td colSpan="7" className="text-center text-danger">Record Not Found</td>
                        </tr>
                            }                      
                        </tbody>
                    </table>
                    
                </div>
            </section> 
            <div className="col-md-12"></div>
            {BtnLoading==false?
            <div className="d-grid col-md-2 mt-2">
                <button onClick={()=>GenrateShipmentManifest()} className="btn btn-primary">Generate Manifest</button>
            </div>
            :
            <div className="d-grid col-md-2 mt-2">
                <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
            </div>
            }
            
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default AddForwardShipmentManifest;